<template>
  <v-container>
    <v-row>
      <v-col>
        <v-simple-table
            fixed-header
            height="450px"
        >
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left">Job No</th>
              <th class="text-left">DRS No</th>
              <th class="text-left">Customer</th>
              <th class="text-left">Date</th>
              <th class="text-left">Site</th>
              <th class="text-left">Chargehand</th>
              <th class="text-left">By</th>
              <th class="text-left">Checked?</th>
              <th class="text-left">Hours added?</th>
              <th class="text-left">Use for pay?</th>
              <th class="text-left">Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="(drs, index) in dailyReturnSheets"
                :key="index"
            >
              <td>{{ drs.jobNumber }}</td>
              <td>{{ drs.palNumber }}</td>
              <td>{{ drs.customer }}</td>
              <td>{{ drs.work_date | tinyDate }}</td>
              <td>{{ drs.site }}</td>
              <td>{{ drs.chargehand }}</td>
              <td>{{ drs.quotedByInitials }}</td>
              <td>
                <v-checkbox disabled :ripple="false" v-model="drs.confirmed"></v-checkbox>
              </td>
              <td>
                {{  drs.hasHours }}
              </td>
              <td>
                <v-checkbox disabled :ripple="false" v-model="drs.send_to_pay_report"></v-checkbox>
              </td>
              <td class="pr-0">
                <router-link custom v-slot="{ navigate }" :to="`/drs/view/${drs.id}`">
                  <v-btn
                      title="View"
                      text
                      x-small
                      @click="navigate"
                      @keypress.enter="navigate"
                      role="link">
                    View
                  </v-btn>
                </router-link>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-pagination
            class="pt-4"
            v-model="page"
            :length="length"
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            :total-visible="totalVisible"
        ></v-pagination>
      </v-col>
    </v-row>
    <v-row class="no-print">
      <v-col>
        <download-csv
          name="drs.csv"
          :data="dailyReturnSheets"
          :fields="exportFields">
          <v-btn>Export</v-btn>
        </download-csv>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "@/axios";

export default {
  name: 'DrsJobTypeBreakdown',
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      exportFields: [
        'jobNumber',
        'customer',
        'work_date',
        'site',
        'chargehand',
      ],
      dailyReturnSheets: [],
      page: 1,
      length: 0,
      totalVisible: 9,
      date: [],
      dateDialogue: false,
      modal: false,
      customer: '',
      salesPerson: '',
      chargehand: '',
      jobNumber: '',
      drsNumber: '',
      drsToDelete: -1,
      confirmDeleteDrs: false,
    };
  },
  watch: {
    page() {
      this.getJobs();
    },
  },
  methods: {
    getDrs() {
      const postData = {};
      postData.type = this.$route.params.type;
      postData.dateFrom = this.$route.params.dateFrom;
      postData.dateTo = this.$route.params.dateTo;
      axios.post(`/dailyReturnSheets/getAll/${this.page}.json?token=${this.token}`, postData)
          .then((response) => {
            this.dailyReturnSheets = response.data.dailyReturnSheets;
            this.length = response.data.length;
            this.total = response.data.total;
          })
          .catch((error) => {
            console.log(error);
          });
    },
  },
  mounted() {
    this.getDrs();
  },
};
</script>
